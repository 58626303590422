






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import ItemHistory from "@/components/ItemHistory.vue";

function tariffItemsInput() {
  return {
    type: "XArrayInput",
    attrs: {
      label: "Bảng phí",
      xOptions: {
        content: {
          itemLabel: (item) =>
            `${Vue.filter("currency")(item.minimumAdsValue) || ""} - ${
              Vue.filter("currency")(item.maximumAdsValue) || ""
            }: ${
              (item.fixCost && Vue.filter("currency")(item.fixCost)) || (item.percentage && item.percentage + "%") || ""
            }`,
          itemProperties: {
            accountType: {
              attrs: {
                label: "Loại TK",
              },
            },
            minimumAdsValue: {
              type: "integer",
              attrs: {
                label: "Min",
              },
            },
            maximumAdsValue: {
              type: "integer",
              attrs: {
                label: "Max",
                required: true,
              },
            },
            fixCost: {
              type: "integer",
              attrs: {
                label: "Giá fix",
              },
            },
            percentage: {
              type: "number",
              attrs: {
                label: "Tỉ lệ %",
              },
            },
          },
          template: {
            formOptions: {
              content: {
                colLength: 2,
              },
            },
          },
        },
      },
    },
  };
}

function displayFields(adsAgencyId, item) {
  return {
    name: {
      text: "Tên",
      sortable: true,
    },
    description: {
      text: "Mô tả",
    },
    taxPercentage: {
      text: "% thuế",
    },
    feePercentage: {
      text: "% phí",
    },
    adsCard: item.type === "tkcn" && {
      text: "Thẻ",
      sortable: true,
      options: {
        label: true,
        subProp: "adsCard.name",
        sortBy: "adsCardId",
      },
    },
    createdTime: {
      text: "Ngày tạo",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    lastFeePercentageStartTime: {
      text: "Ngày chuyển % phí",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    lastTaxPercentageStartTime: {
      text: "Ngày chuyển % tax",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    lastTariffItemsStartTime: {
      text: "Ngày chuyển bảng phí",
      sortable: true,
      options: {
        filter: "dateTime",
      },
    },
    action: {},
  };
}

export default Vue.extend({
  data() {
    return {
      self: this,
      item: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(adsAgencyId, item) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý TKQC",
          urlEnabled: true,
          search: {},
          displayFields: displayFields(adsAgencyId, item),
          topActionButtons: {
            insert: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "60%",
                  },
                },
              },
            },
            import: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "adsAgencyAccounts",
                                  "import",
                                  {
                                    adsAgencyId,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("adsAgencyAccounts", `@/export`, findAllOptions);
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "60%",
                    },
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
              presetAdsCardId: userManager.checkRole(["kt", "ksnb", "ksnbl"]) &&
                item.type === "tkcn" && {
                  attrs: {},
                  content: {
                    icon: "mdi-card-account-details",
                  },
                  target: {
                    tooltip: {
                      content: {
                        text: "Cập nhật thẻ",
                      },
                    },
                    dialog: {
                      handlers: {
                        initialize() {
                          this.options.content.buttons.save.states.enabled = new DataContainer(false);
                        },
                      },
                      content: {
                        toolbar: {
                          title: "Cập nhật thẻ",
                          subTitle(dialog) {
                            const btn = dialog.context();
                            const { item } = btn.context();
                            return item.name;
                          },
                        },
                        content: {
                          type: "XForm",
                          makeAttrs(attrs, dialog) {
                            const { item } = dialog.context().context();
                            attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                            attrs.xContext = dialog;
                            attrs.xData = new DataContainer({
                              nextAdsCardId: item.nextAdsCardId,
                              nextAdsCardIdStartTime: item.nextAdsCardIdStartTime,
                            });
                            attrs.xOptions = {
                              content: {
                                sections: {
                                  default: {
                                    fields: {
                                      nextAdsCardId: {
                                        type: "XAutocomplete",
                                        attrs: {
                                          label: "Thẻ",
                                          "item-value": "_id",
                                          "item-text": "name",
                                          required: true,
                                          xOptions: {
                                            content: {
                                              async itemsSuggestor(search, value, limit) {
                                                const { items } = await coreApiClient.call("adsCards", "findAll", {
                                                  payload: JSON.stringify({
                                                    limit,
                                                    search,
                                                    filters: [
                                                      {
                                                        key: "_id",
                                                        operator: "equal_to",
                                                        value,
                                                      },
                                                    ],
                                                  }),
                                                });
                                                return items;
                                              },
                                            },
                                          },
                                        },
                                      },
                                      nextAdsCardIdStartTime: {
                                        type: "XDateTimePicker",
                                        attrs: {
                                          label: "Ngày chuyển thẻ",
                                          xOptions: {
                                            content: {
                                              resetable: true,
                                            },
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            };
                            return attrs;
                          },
                          attrs: {},
                        },
                        buttons: {
                          save: {
                            content: {
                              text: "Cập nhật",
                            },
                            states: {},
                            on: {
                              async xClick({ self }) {
                                const dialog = self.context();
                                const btn = dialog.context();
                                const { item, dataTable } = btn.context();
                                const form = dialog.contentContainer.value;
                                const data = form.getData();
                                self.loading.value = true;
                                const result = await dataTable.execute(
                                  async () => {
                                    return await coreApiClient.call(
                                      "adsAgencyAccounts",
                                      "update",
                                      {
                                        id: item._id,
                                      },
                                      {
                                        data: {
                                          nextAdsCardId: data.nextAdsCardId,
                                          nextAdsCardIdStartTime: data.nextAdsCardIdStartTime,
                                        },
                                      }
                                    );
                                  },
                                  undefined,
                                  {
                                    disableLoading: true,
                                    willRefresh: true,
                                  }
                                );
                                self.loading.value = false;
                                if (result) {
                                  dialog.hide();
                                }
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              presetFeePercentage: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật % phí",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật % phí",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextFeePercentage: item.nextFeePercentage,
                            nextFeePercentageStartTime: item.nextFeePercentageStartTime,
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextFeePercentage: {
                                      type: "number",
                                      attrs: {
                                        label: "% phí mới",
                                        autofocus: true,
                                      },
                                    },
                                    nextFeePercentageStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển % phí",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "adsAgencyAccounts",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextFeePercentage: data.nextFeePercentage,
                                        nextFeePercentageStartTime: data.nextFeePercentageStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              presetTaxPercentage: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật % thuế",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật % thuế",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextTaxPercentage: item.nextTaxPercentage,
                            nextTaxPercentageStartTime: item.nextTaxPercentageStartTime,
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextTaxPercentage: {
                                      type: "number",
                                      attrs: {
                                        label: "% thuế mới",
                                        autofocus: true,
                                      },
                                    },
                                    nextTaxPercentageStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển % thuế",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "adsAgencyAccounts",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextTaxPercentage: data.nextTaxPercentage,
                                        nextTaxPercentageStartTime: data.nextTaxPercentageStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              presetTariffItems: userManager.checkRole(["kt", "ksnb", "ksnbl"]) && {
                attrs: {},
                content: {
                  icon: "mdi-currency-usd",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Cập nhật bảng phí",
                    },
                  },
                  dialog: {
                    attrs: {
                      width: "800px",
                    },
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    content: {
                      toolbar: {
                        title: "Cập nhật bảng phí",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          const { item } = dialog.context().context();
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({
                            nextFeePercentage: item.nextFeePercentage,
                            nextFeePercentageStartTime: item.nextFeePercentageStartTime,
                          });
                          attrs.xOptions = {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    nextTariffItems: tariffItemsInput(),
                                    nextTariffItemsStartTime: {
                                      type: "XDateTimePicker",
                                      attrs: {
                                        label: "Ngày chuyển bảng phí",
                                        xOptions: {
                                          content: {
                                            resetable: true,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          };
                          return attrs;
                        },
                        attrs: {},
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Cập nhật",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { item, dataTable } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              const result = await dataTable.execute(
                                async () => {
                                  return await coreApiClient.call(
                                    "adsAgencyAccounts",
                                    "update",
                                    {
                                      id: item._id,
                                    },
                                    {
                                      data: {
                                        nextTariffItems: data.nextTariffItems,
                                        nextTariffItemsStartTime: data.nextTariffItemsStartTime,
                                      },
                                    }
                                  );
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: true,
                                }
                              );
                              self.loading.value = false;
                              if (result) {
                                dialog.hide();
                              }
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
              actionHistory: {
                content: {
                  icon: "mdi-format-list-bulleted-square",
                },
                target: {
                  tooltip: { content: { text: "Lịch sử tác động" } },
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    content: {
                      autofocus: false,
                      toolbar: {
                        title: "Lịch sử tác động",
                        subTitle(self) {
                          const btn = self.context();
                          const { item } = btn.context();
                          return item.name;
                        },
                      },
                      contentIsNotDefaultComponent: true,
                      content: {
                        type: ItemHistory,
                        attrs: {
                          targetType: "AdsAgencyAccount",
                          displayFields: {
                            ...displayFields(adsAgencyId, item),
                            nextFeePercentage: {
                              text: "% phí mới",
                            },
                            nextFeePercentageStartTime: {
                              text: "Ngày chuyển % phí mới",
                              options: {
                                filter: "dateTime",
                              },
                            },
                            nextTariffItems: {
                              text: "Bảng phí mới",
                              options: {
                                labelItems(value) {
                                  return `min=${value.minimumAdsValue}; max=${value.maximumAdsValue}; %=${value.percentage}`;
                                },
                              },
                            },
                            nextTariffItemsStartTime: {
                              text: "Ngày chuyển bảng phí mới",
                              options: {
                                filter: "dateTime",
                              },
                            },
                            tariffItems: {
                              text: "Bảng phí",
                              options: {
                                labelItems(value) {
                                  return `min=${value.minimumAdsValue}; max=${value.maximumAdsValue}; %=${value.percentage}`;
                                },
                              },
                            },
                          },
                          hiddenFields: ["adsAgency"],
                        },
                        makeAttrs(attrs: any = {}, dialog) {
                          const { item } = dialog.context().context();
                          attrs.item = item;
                          return attrs;
                        },
                      },
                      buttons: {
                        cancel: {
                          content: {
                            text: "Đóng",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "adsAgencyId",
              operator: "equal_to",
              value: this.context().$route.params.adsAgencyId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("adsAgencyAccounts", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.adsAgencyId = dataTable.context().$route.params.adsAgencyId;
              return await coreApiClient.call("adsAgencyAccounts", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "adsAgencyAccounts",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("adsAgencyAccounts", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              adsCardId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Thẻ",
                  "item-value": "_id",
                  "item-text": "name",
                  required: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("adsCards", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                ext: {
                  condition() {
                    return item.type === "tkcn";
                  },
                },
              },
              taxPercentage: {
                type: "number",
                attrs: {
                  label: "% thuế",
                },
              },
              feePercentage: {
                type: "number",
                attrs: {
                  label: "% phí",
                },
              },
              tariffItems: tariffItemsInput(),
            },
            insertForm: {
              content: {
                fieldNames: ["name", "description", "adsCardId", "taxPercentage", "feePercentage", "tariffItems"],
              },
            },
            editForm: {
              content: {
                fieldNames: ["name", "description", "adsCardId", "taxPercentage", "feePercentage", "tariffItems"],
              },
            },
            delete: {
              confirmDisplayField: "name",
            },
          },
        },
      };
    },
  },
  async created() {
    this.item = await coreApiClient.call("adsAgencies", "findById", {
      id: this.$route.params.adsAgencyId,
    });
    this.tableOptions = this.makeTableOptions(this.$route.params.adsAgencyId, this.item);
  },
});
